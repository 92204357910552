import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    result: PropTypes.object,
}

const ServiceInfo = ({ className, result }) => {
    return result ? (
        <div className={classNames('product-info', className)}>
            For more information on {result.title} contact Ken&rsquo;s Foods at <span className="no-wrap">508-948-4724</span> <span className="no-wrap">(8:30 AM - 5:00 PM EST M-F)</span>
        </div>
    ) : null
}

ServiceInfo.propTypes = propTypes

export default ServiceInfo
