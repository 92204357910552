import React from 'react'
import PropTypes from 'prop-types'

import ServiceSubhead from '../../../service-parts/ServiceSubhead'
import Example from './Example'

const propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        instructions: PropTypes.array,
    }),
}

const Instructions = ({ data }) => {
    return (
        <div className="instructions">
            <h3>{data.title}</h3>
            <ServiceSubhead>{data.subTitle}</ServiceSubhead>
            <div className="example-wrapper">
                {data.instructions.map((item, index) => {
                    return (
                        <Example
                            key={index}
                            src={item.src}
                            description={item.description}
                        />
                    )
                })}
            </div>
        </div>
    )
}

Instructions.propTypes = propTypes

export default Instructions
