import { actionTypes as types } from './constants'

export const submitPurchaseForm = (payload = {}) => ({
    type: types.FAQ_SUBMIT_PURCHASE_FORM_START,
    payload,
})

export const clearPurchaseForm = () => ({
    type: types.FAQ_CLEAR_PURCHASE_FORM,
})

export const fetchAllProducts = () => ({
    type: types.FAQ_FETCH_ALL_PRODUCTS_START,
})
