import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { connect } from 'react-redux'
import classNames from 'classnames'

import FieldWrapper from '../../../../../components/forms/form-parts/FieldWrapper'
import InputField from '../../../../../components/forms/form-fields/InputField'
import StateField from '../../../../../components/forms/form-fields/StateField'
import TextAreaField from '../../../../../components/forms/form-fields/TextAreaField'
import SelectField from '../../../../../components/forms/form-fields/SelectField'
import Modal from '../../../../../components/modal/Modal'
import ReCaptcha from '../../../../../components/re-captcha/ReCaptcha'
import { getFullProductName } from '../../../utils/helpers'
import { submitPurchaseForm, clearPurchaseForm } from '../../../store/actions'
import { contactRegions, regionalManagers } from '../../../config'
import ProductsField from '../../forms/form-fields/ProductsField'
import Form from '../../forms/form-parts/Form'
import FormActions from '../../forms/form-parts/FormActions'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import { getRegionalManagerId } from './helpers'
import defaultValues from './defaultValues'
import schema from './schema'

const PurchaseService = ({
    loading,
    success,
    error,
    submitPurchaseForm,
    clearPurchaseForm,
    // store
    products,
    // router
    location,
}) => {
    const methods = useForm({
        mode: 'onBlur',
        defaultValues,
        resolver: yupResolver(schema),
    })

    const { reset, handleSubmit, watch, setValue } = methods

    // set product from url
    useEffect(() => {
        if (!products || !products.length || !location.search) {
            return
        }

        const params = new URLSearchParams(location.search)
        const productFromUrl = params.get('product')

        if (productFromUrl) {
            const targetProduct = products.find(
                ({ _id }) => _id === productFromUrl
            )

            if (targetProduct) {
                setValue('products', [targetProduct])
            }
        }
    }, [products, location.search, setValue])

    const recaptchaRef = useRef()

    const [captcha, setCaptcha] = useState(null)

    const [captchaValidation, setCaptchaValidation] = useState(null)

    const [modalVisible, setModalVisible] = useState()

    const onSubmit = (formValues) => {
        if (!captcha) {
            setCaptchaValidation('Please complete the reCAPTCHA')

            return
        }

        const products = formValues.products.map((product) =>
            getFullProductName(product)
        )

        const managerId = getRegionalManagerId(
            formValues.state,
            formValues.region
        )

        submitPurchaseForm({
            formValues: { ...formValues, products },
            managerId,
            captcha,
        })
    }

    const handleCaptchaChange = useCallback(
        (value) => {
            setCaptcha(value)

            setCaptchaValidation(null)
        },
        [setCaptcha, setCaptchaValidation]
    )

    const onModalClose = useCallback(
        (e) => {
            e && e.preventDefault()

            setModalVisible(false)

            if (success) {
                reset()

                clearPurchaseForm()

                setCaptcha(null)

                recaptchaRef.current.reset()

                setCaptchaValidation(null)
            }
        },
        [reset, clearPurchaseForm, success, recaptchaRef]
    )

    useEffect(() => {
        if (success || error) {
            setModalVisible(true)
        }
    }, [success, error])

    const [regions, setRegions] = useState([])

    const [currentState, currentRegion, hasRegion] = watch([
        'state',
        'region',
        'hasRegion',
    ])

    // update regions when state changes
    useEffect(() => {
        let regions = []

        if (currentState) {
            const targetRegions = contactRegions.find(
                (r) => r.value === currentState
            )

            if (targetRegions && targetRegions.regions) {
                regions = [
                    ...targetRegions.regions,
                    { value: 'other', label: 'Other' },
                ]
            }
        }

        setRegions(regions)

        setValue('hasRegion', !!regions.length)

        setValue('region', '')

        setValue('city', '')
    }, [currentState])

    // clear city field when region changes
    useEffect(() => {
        setValue('city', '')
    }, [currentRegion])

    const activeManager = useMemo(() => {
        if (currentState && (!hasRegion || currentRegion)) {
            const managerId = getRegionalManagerId(currentState, currentRegion)

            if (managerId && regionalManagers[managerId]) {
                return regionalManagers[managerId]
            }
        }

        return null
    }, [currentState, currentRegion, hasRegion])

    const resetForm = (e) => {
        e && e.preventDefault()

        reset()
    }

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <>
                    Contact us and you will be contacted by a Ken&rsquo;s
                    representative in your area. If you need immediate
                    assistance call 508-948-4724.
                </>
            </ServiceSubhead>
            <p>
                <em>(Fields with * are required)</em>
            </p>
            <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <FieldWrapper name="state">
                    <StateField
                        name="state"
                        placeholder="Select state*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className={classNames({
                        // region is visible if it state has regions
                        'hidden-element': !regions || !regions.length,
                    })}
                    name="region"
                >
                    <SelectField
                        name="region"
                        placeholder="Select region*"
                        disabled={loading}
                        options={regions}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className={classNames({
                        // city is visible only if no regions or region is 'other'
                        'hidden-element': !(
                            currentRegion === 'other' ||
                            (currentState && (!regions || !regions.length))
                        ),
                    })}
                    name="city"
                >
                    <InputField
                        name="city"
                        placeholder="Select city*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="products">
                    <ProductsField
                        isMulti
                        name="products"
                        placeholder="Select product / Enter product code*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="company">
                    <InputField
                        name="company"
                        placeholder="Company name*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="fullName">
                    <InputField
                        name="fullName"
                        placeholder="Your name*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="email">
                    <InputField
                        name="email"
                        type="email"
                        placeholder="Email address*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="phone">
                    <InputField
                        name="phone"
                        type="tel"
                        placeholder="Phone number"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="message">
                    <TextAreaField
                        name="message"
                        placeholder="Please provide more information to help us direct your inquiry*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <ReCaptcha
                    recaptchaRef={recaptchaRef}
                    onChange={handleCaptchaChange}
                />
                {captchaValidation && (
                    <p style={{ color: '#900' }}>{captchaValidation}</p>
                )}
                <FormActions onClear={resetForm} submitLabel="Submit" />
                {/* <button type="submit" disabled={loading}>
                    {loading ? 'Sending...' : 'Submit'}
                </button> */}
            </Form>
            {activeManager ? (
                <div>
                    Our manager in your area is{' '}
                    <strong>{activeManager.name}</strong>
                </div>
            ) : null}
            <Modal visible={modalVisible} onClose={onModalClose}>
                <div className="modal-content">
                    {error ? (
                        <p>{error}</p>
                    ) : (
                        <>
                            <h3>Thank you for contacting Ken&rsquo;s!</h3>
                            <p>
                                A Ken&rsquo;s representative will contact you
                                soon about your product request.
                            </p>
                        </>
                    )}
                </div>
            </Modal>
        </ServiceWrapper>
    )
}

const mapStateToProps = (state) => ({
    products: state.faq.products.list,
    loading: state.faq.purchaseForm.loading,
    success: state.faq.purchaseForm.success,
    error: state.faq.purchaseForm.error,
})

export default withRouter(
    connect(mapStateToProps, {
        submitPurchaseForm,
        clearPurchaseForm,
    })(PurchaseService)
)
