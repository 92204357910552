import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import InputError from '../form-parts/InputError'
import SelectFieldRaw from './raw/SelectField'

const SelectField = ({ name, noError, ...selectProps }) => {
    const { control } = useFormContext()

    return (
        <div className="field-wrapper">
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return <SelectFieldRaw {...selectProps} {...field} />
                }}
            />
            {!noError ? <InputError name={name} /> : null}
        </div>
    )
}

export default SelectField
