import React from 'react'

import InputField from './InputField'
import JulianDateFieldRaw from './raw/JulianDateField'

export const JulianDateField = (props) => {
    return <JulianDateFieldRaw {...props} as={InputField} />
}

export default JulianDateField
