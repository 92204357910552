import React from 'react'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import _get from 'lodash/get'

import { getFieldValidationClass } from '../../../helpers/utils'
import FieldWrapperRaw from './raw/FieldWrapper'

const FieldWrapper = ({ className, name, ...rest }) => {
    const {
        formState: { dirtyFields, errors },
    } = useFormContext()

    const validationClasses = name
        ? getFieldValidationClass(name, { errors, dirtyFields })
        : ''

    const errorMessage = name && _get(errors, `${name}.message`)

    return (
        <FieldWrapperRaw
            className={classNames(className, validationClasses)}
            errorMessage={errorMessage}
            {...rest}
        />
    )
}
export default FieldWrapper
