import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { connect } from 'react-redux'

import FieldWrapper from '../../../../../../components/forms/form-parts/FieldWrapper'
import ProductCodeField from '../../../../../../components/forms/form-fields/ProductCodeField'
import Form from '../../form-parts/Form'
import FormActions from '../../form-parts/FormActions'
import ProductsField from '../../form-fields/ProductsField'
import defaultValues from './defaultValues'
import schema from './schema'

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    submitButtonLabel: PropTypes.string,
}

const FindProductForm = ({
    onSubmit,
    onChange,
    onReset,
    submitButtonLabel,
    // store
    products,
    productsLoading,
    productsError,
}) => {
    // const [selectedProductName, setSelectedProductName] = useState()

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    })

    const {
        reset,
        handleSubmit,
        watch,
        setValue,
        setError,
        formState: { isValid },
    } = methods

    const [formFreeze, setFormFreeze] = useState(false)

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (value[name] !== '') {
                // only one field should be filled at a time
                Object.keys(value).forEach((key) => {
                    if (key !== name && value[key] !== '') {
                        setValue(key, '', { shouldValidate: true })
                    }
                })
            }

            // pass selected product to parent component
            let selectedProduct

            if (name === 'product' && value[name]) {
                selectedProduct = value[name]
            } else if (
                name === 'upc' &&
                value[name] &&
                value[name].length >= 12
            ) {
                selectedProduct = products.find((p) => p.upc === value[name])
            }

            onChange(selectedProduct)
        })

        return () => subscription.unsubscribe()
    }, [watch, setValue, onChange, products])

    const submitForm = (formValues) => {
        let selectedProduct

        const { product, upc } = formValues

        if (product) {
            selectedProduct = product
        } else if (upc) {
            selectedProduct = products.find((p) => p.upc === upc)

            if (!selectedProduct) {
                setError('upc', {
                    type: 'manual',
                    message: 'Product with this UPC code not found',
                })
            }
        }

        if (selectedProduct) {
            // if (selectedProduct.name) {
            //     setSelectedProductName(selectedProduct.name)
            // }

            setFormFreeze(true)

            onSubmit(selectedProduct)
        }
    }

    const resetForm = (e) => {
        e && e.preventDefault()

        setFormFreeze(false)

        // setSelectedProductName()

        reset()

        onReset && onReset()
    }

    return (
        <Form methods={methods} onSubmit={handleSubmit(submitForm)}>
            <FieldWrapper name="product">
                <ProductsField name="product" disabled={formFreeze} />
            </FieldWrapper>
            <FieldWrapper name="upc">
                <ProductCodeField
                    name="upc"
                    placeholder={
                        productsLoading
                            ? 'Loading...'
                            : productsError
                              ? 'Eror. Reaload the page'
                              : 'UPC Code'
                    }
                    disabled={formFreeze || productsLoading}
                />
            </FieldWrapper>
            {isValid ? (
                <FormActions
                    onClear={resetForm}
                    disabled={formFreeze}
                    submitLabel={submitButtonLabel}
                />
            ) : null}
            {/* {selectedProductName ? (
                <div className="product-info">
                    Click here for more information on {selectedProductName} or
                    contact Ken’s Foods at 508-948-4724 (8:30AM 5:00PM E ST M-
                    F)
                </div>
            ) : null} */}
        </Form>
    )
}

FindProductForm.propTypes = propTypes

const mapStateToProps = (state) => ({
    products: state.faq.products.list,
    productsLoading: state.faq.products.loading,
    productsError: state.faq.products.error,
})

export default connect(mapStateToProps)(FindProductForm)
