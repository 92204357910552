import * as yup from 'yup'

import yupMessages from '../../../../../helpers/yup/yup-messages'

yupMessages()

const schema = yup.object({
    state: yup.string().max(2).required(),
    hasRegion: yup.boolean().required(),
    region: yup.string().when('hasRegion', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional(),
    }),
    city: yup
        .string()
        .max(500)
        .test('city', 'This field is required', function (value) {
            const { hasRegion, region } = this.parent

            if (!hasRegion || (hasRegion && region === 'other')) {
                return value && value.length > 0
            }

            return true
        }),
    products: yup.array().min(1).required(),
    company: yup.string().max(500).required(),
    fullName: yup.string().min(3).max(500).required(),
    email: yup.string().email().max(200).required(),
    phone: yup.string().max(200).required(),
    message: yup.string().min(10).max(5000).required(),
})

export default schema
