import _merge from 'lodash/merge';
import _clone from 'lodash/clone';

import {
    LOAD_LOOKUP_DATA_COMPLETE
} from '../actions/types';

const initialState = {
    menuItems: [
        {
            name: 'products',
            target: '/products/story',
            gtm: 'data-gtm-products-link-a',
            subItems: [
                {
                    name: 'flavor/type',
                    stateName: 'flavor',
                    gtm: 'data-gtm-flavor-type-link-a',
                    subItems: [] // to be loaded by dataset
                },
                {
                    name: 'product line',
                    stateName: 'productLines',
                    gtm: 'data-gtm-product-line-link-a',
                    subItems: []
                },
                {
                    name: 'packaging',
                    stateName: 'package',
                    gtm: 'data-gtm-packaging-link-a',
                    subItems: []
                },
                {
                    name: 'all products',
                    target: '/products',
                    gtm: 'data-gtm-all-products-link-a'
                }
            ]
        },
        {
            name: 'recipes & menu trends',
            target: '/recipes',
            gtm: 'data-gtm-recipes-and-menu-trends-a',
            subItems: [
                {
                    name: 'dish category',
                    hash: '#allRecipes',
                    stateName: 'dishes',
                    gtm: 'data-gtm-dish-category-link-a',
                    subItems: []
                },
                {
                    name: 'flavor category',
                    hash: '#allRecipes',
                    stateName: 'flavor',
                    gtm: 'data-gtm-flavor-category-link-a',
                    subItems: []
                },
                {
                    name: 'all recipes',
                    target: '/recipes#allRecipes',
                    class: 'no-sign',
                    gtm: 'data-gtm-all-recipes-link-a'
                },
                {
                    name: 'what\'s hot now',
                    target: '/whats-hot',
                    gtm: 'data-gtm-whats-hot-now-link-a'
                }
            ]
        },
        {
            name: 'make it your own',
            target: '/plus-one',
            gtm: 'data-gtm-make-it-your-own-link-a'
        },
        {
            name: 'about us',
            target: '/about-us',
            gtm: 'data-gtm-about-us-link-a',
            subItems: [
                {
                    name: 'contact us',
                    target: '/contact',
                    class: 'no-sign',
                    gtm: 'data-gtm-contact-us-link-a'
                },
                {
                    name: 'faq',
                    target: '/faq',
                    gtm: 'data-gtm-faq-link-a'
                }
            ]
        },
        {
            name: 'request a sample',
            target: '/get-sample',
            class: 'button',
            gtm: 'data-gtm-request-a-sample-link-a'
        }
    ],
    flavors: [],
    productlines: [],
    packagings: [],
    dishes: [],
    trends: []
};

export default (state = initialState, action) => {

    let updatedState = _clone(state);

    const prepareGTMString = (name) => {
        const cleared = name.replace(/'/g, '').replace(/[/ ]/g, '-').replace(/&/g, 'and');
        return `data-gtm-${cleared.toLowerCase()}-link-a`;
    }

    switch (action.type) {
        case LOAD_LOOKUP_DATA_COMPLETE:
            if (action.payload) {
                const dataMap = [
                    {name: 'flavors', mainMenu: 0, subMenu: 0},
                    {name: 'flavors', mainMenu: 1, subMenu: 1},
                    {name: 'productLines', mainMenu: 0, subMenu: 1},
                    {name: 'packagings', mainMenu: 0, subMenu: 2},
                    {name: 'dishes', mainMenu: 1, subMenu: 0}
                ]

                dataMap.forEach((m) => {
                    if (action.payload[m.name]) {
                        const preparedPayload = action.payload[m.name].map((item) => ({
                            ...item,
                            gtm: prepareGTMString(item.name)
                        }));

                        updatedState.menuItems[m.mainMenu].subItems[m.subMenu].subItems = preparedPayload;
                    }
                });

                updatedState = _merge({}, updatedState, action.payload);
            }
            break;
        default:
            break;
    }

    return updatedState
}
