import React from 'react'

import ServiceWrapper from '../../service-parts/ServiceWrapper'

import mfgCodeImage from '../../../../../assets/img/faq/mfg-code.jpg'

const MGFReadService = () => {
    return (
        <ServiceWrapper>
            <p><em>Example: 112 24 M1 15:20</em></p>
            <p><img src={mfgCodeImage} alt="MFG example" /></p>
            <table>
                <thead>
                    <tr>
                        <th>code</th>
                        <th>means</th>
                        <th>explanation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>112</td>
                        <td>Date</td>
                        <td>Day of the year that the product was manufactured (in this case, the 112th day of 2021, which was April 22).</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>Year</td>
                        <td>Year that the product was manufactured (2024).</td>
                    </tr>
                    <tr>
                        <td>M</td>
                        <td>Facility</td>
                        <td>
                            Facility where the product was manufactured:<br/>
                            A = Atlanta, M = Marlborough, V = Las Vegas, B = Indiana.
                        </td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Line</td>
                        <td>Production line that the product was manufactured on.</td>
                    </tr>
                    <tr>
                        <td>15:20</td>
                        <td>Time</td>
                        <td>Time that the product was packaged.</td>
                    </tr>
                </tbody>
            </table>
            <p><em>This product was manufactured on April 22, 2024 at 3:20 pm, on Line 1 of Ken&rsquo;s Marlborough plant.</em></p>
            <button onClick={()=> window.open("/products", "_blank")} className="big-btn">Browse all products</button>
        </ServiceWrapper>
    )
}

export default MGFReadService
