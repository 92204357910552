import React from 'react'

import gallonImage1 from '../../../../../assets/img/faq/gallon-mfg-1.jpg'
import gallonImage2 from '../../../../../assets/img/faq/gallon-mfg-2.jpg'
import pouchImage1 from '../../../../../assets/img/faq/pouch-mfg-1.jpg'
import pouchImage2 from '../../../../../assets/img/faq/pouch-mfg-2.jpg'
import cupImage1 from '../../../../../assets/img/faq/cup-mfg-1.jpg'
import cupImage2 from '../../../../../assets/img/faq/cup-mfg-2.jpg'

export const options = [
    {
        value: 'gallon',
        label: 'Gallon',
        title: 'Round gallons',
        subTitle:
            'All round gallon products show the manufacture date and date coding on both the case and the gallons.',
        instructions: [
            {
                src: `${gallonImage1}`,
                description: (
                    <>
                        The date coding and manufacture date are printed on the{' '}
                        <strong>shoulder of the gallons</strong>.
                    </>
                ),
            },
            {
                src: `${gallonImage2}`,
                description: (
                    <>
                        For gallon cases, the manufacture date and date coding are printed on{' '} <strong>adjacent sides of the case</strong>. The manufacture date is followed by the date coding. They are located above the bar code.
                    </>
                ),
            },
        ],
    },
    {
        value: 'pouch',
        label: 'Pouch',
        title: 'Portion control pouches',
        subTitle:
            'Ken’s portion control pouches show the expiration date on each individual pouch, as well as the case.',
        instructions: [
            {
                src: `${pouchImage1}`,
                description: (
                    <>
                        The expiration date is imprinted on the{' '}
                        <strong>crimped edge of the individual pouch</strong>.
                    </>
                ),
            },
            {
                src: `${pouchImage2}`,
                description: (
                    <>
                        For portion control pouch cases, the expiration date and date coding are right above the bar code on{' '}
                        <strong>adjacent sides of each case</strong>.
                    </>
                ),
            },
        ],
    },
    {
        value: 'cup',
        label: 'Cup',
        title: 'Portion control cups',
        subTitle:
            'Ken’s portion control cups show the expiration date on each individual cup lid, as well as the case.',
        instructions: [
            {
                src: `${cupImage1}`,
                description: (
                    <>
                        The expiration date is on the{' '}
                        <strong>individual cup lid</strong>.
                    </>
                ),
            },
            {
                src: `${cupImage2}`,
                description: (
                    <>
                        For portion control cup cases, the expiration date and date coding are right above the bar code on{' '}
                        <strong>adjacent sides of each case</strong>.
                    </>
                ),
            },
        ],
    },
]
