import _get from 'lodash/get'

import fallbackImgSrc from '../assets/img/icon-cross-orange.png'

export const getFieldValidationClass = (name, formState) => {
    const isDirty = _get(formState.dirtyFields, name)

    const isError = _get(formState.errors, name)

    return isError ? 'is-invalid' : isDirty && !isError ? 'is-valid' : ''
}

export const handleImgError = (e) => {
    e.target.src = fallbackImgSrc
    e.target.onerror = null // Prevents infinite loop if fallback also fails
}
