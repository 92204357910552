import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    onClear: PropTypes.func,
    disabled: PropTypes.bool,
    submitLabel: PropTypes.string,
}

const Result = ({ className, onClear, disabled, submitLabel }) => {
    return (
        <div className={classNames('form-actions', className)}>
            <button onClick={onClear} className="big-btn">Clear</button>
            <button disabled={disabled} className="big-btn" type="submit">
                {submitLabel}
            </button>
        </div>
    )
}

Result.propTypes = propTypes

export default Result
