import React, { useState } from 'react'

import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import SelectField from '../../../../../components/forms/form-fields/raw/SelectField'
import FieldWrapper from '../../../../../components/forms/form-parts/raw/FieldWrapper'
import Instructions from './components/Instructions'
import { options } from './config'

const MFGShowService = () => {
    const [currentOption, setCurrentOption] = useState()

    const sizeLinkSwitcher = () => {
        switch (currentOption.value) {
            case 'gallon':
                return window.open("/products?package=5b6c4eaeb7400b01f9a16e00", "_blank");
            case 'pouch':
                return window.open("/products?package=5b6c4ea8b7400b01f9a16b68", "_blank");
            case 'cup':
                return window.open("/products?package=5b6c4ea8b7400b01f9a16b66", "_blank");
            default:
                return null;
        }
    }

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                Please select the product size:
            </ServiceSubhead>
            <FieldWrapper name="size">
                <SelectField
                    name="size"
                    placeholder="Select the size"
                    options={options}
                    onChange={(v) => setCurrentOption(v)}
                    getCurrentValue={(v) => v}
                    handleOnChange={(v, onChange) => {
                        onChange(v)
                    }}
                />
            </FieldWrapper>
            {currentOption ? (
                <>
                    <Instructions data={currentOption} />
                    <button onClick={sizeLinkSwitcher} className="big-btn">View all {currentOption.value} products</button>
                </>
            ) : null}
        </ServiceWrapper>
    )
}

export default MFGShowService
