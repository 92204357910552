import { lazy } from 'react'

const AllergenService = lazy(() => import('./components/services/allergen'))
const ExpirationDateService = lazy(
    () => import('./components/services/expiration-date')
)
const GlutenFreeService = lazy(
    () => import('./components/services/gluten-free')
)
const KosherService = lazy(() => import('./components/services/kosher'))
const MGFReadService = lazy(() => import('./components/services/mfg-read'))
const MFGShowService = lazy(() => import('./components/services/mfg-show'))
const PurchaseService = lazy(() => import('./components/services/purchase'))
const ProductActiveService = lazy(
    () => import('./components/services/product-active')
)
const ShelfLifeLocationService = lazy(
    () => import('./components/services/shelf-life-location')
)

export const services = [
    {
        slug: 'allergen',
        navTitle: 'Allergen Checker',
        title: 'Have an allergen question?',
        tooltip: "What is the expiration date of an unopened Ken's gallon?",
        component: AllergenService,
    },
    {
        slug: 'gluten-free',
        navTitle: 'Gluten-Free Checker',
        title: "How to determine if a Ken's Foodservice product is Gluten-Free?",
        tooltip: "Is the Ken's Foodservice product Gluten-Free?",
        component: GlutenFreeService,
    },
    {
        slug: 'kosher',
        navTitle: 'Kosher Checker',
        title: "How can you tell if a Ken's Foodservice product is Kosher or Kosher Dairy?",
        tooltip: "Is the Ken's Foodservice product Kosher or Kosher Dairy?",
        component: KosherService,
    },
    {
        slug: 'manufacture-code-show',
        navTitle: 'MFG (Manufacturing) Code Finder',
        title: "How is a MFG (Manufacture Code) shown on the Ken's product or case of product?",
        tooltip: "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        component: MFGShowService,
    },
    {
        slug: 'manufacture-code-read',
        navTitle: 'How to Read MFG (Manufacturing) Code',
        title: "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        tooltip: "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        component: MGFReadService,
    },
    {
        slug: 'product-active',
        navTitle: 'Product Availability Checker',
        title: "Does Ken's Foodservice still produce the product I am looking for?",
        tooltip: "Does Ken's Foodservice still produce the product I am looking for?",
        component: ProductActiveService,
    },
    {
        slug: 'where-purchase',
        navTitle: 'Product Locator Inquiries',
        title: "Where can I purchase my Ken's Foodservice product?",
        tooltip: "Where can I purchase my Ken's Foodservice product?",
        component: PurchaseService,
    },
    {
        slug: 'product-shelf-life-location',
        navTitle: 'Shelf Life Checker',
        title: "Where on the Ken's Foodservice website is the product shelf life located?",
        tooltip: "Where on the Ken's Foodservice website is the product shelf-life located?",
        component: ShelfLifeLocationService,
    },
    {
        slug: 'expiration-date',
        navTitle: 'Shelf Life Expiration Date Calculator',
        title: "What is the expiration date of an unopened Ken's gallon?",
        tooltip: "What is the expiration date of an unopened Ken's gallon or other sized product?",
        component: ExpirationDateService,
    },
]

export const contactRegions = [
    {
        value: 'AL',
        label: 'Alabama',
        manager: 1,
    },
    {
        value: 'AK',
        label: 'Alaska',
        manager: 2,
    },
    {
        value: 'AZ',
        label: 'Arizona',
        manager: 3,
    },
    {
        value: 'AR',
        label: 'Arkansas',
        manager: 4,
    },
    {
        value: 'CA',
        label: 'California',
        manager: 2,
        regions: [
            {
                value: 'So Ca',
                label: 'So Ca',
                manager: 2,
            },
            {
                value: 'No Ca',
                label: 'No Ca',
                manager: 5,
            },
        ],
    },
    {
        value: 'CO',
        label: 'Colorado',
        manager: 3,
    },
    {
        value: 'CT',
        label: 'Connecticut',
        manager: 7,
        regions: [
            {
                value: 'Metro NY',
                label: 'Metro NY',
                manager: 6,
            },
            {
                value: 'Northern CT',
                label: 'Northern CT',
                manager: 7,
            },
        ],
    },
    {
        value: 'DE',
        label: 'Delaware',
        manager: 8,
    },
    {
        value: 'DC',
        label: 'District Of Columbia',
        manager: 9,
    },
    {
        value: 'FL',
        label: 'Florida',
        manager: 10,
        regions: [
            {
                value: 'North Florida',
                label: 'North Florida',
                manager: 10,
            },
            {
                value: 'South Florida',
                label: 'South Florida',
                manager: 11,
            },
        ],
    },
    {
        value: 'GA',
        label: 'Georgia',
        manager: 1,
    },
    {
        value: 'HI',
        label: 'Hawaii',
        manager: 3,
    },
    {
        value: 'ID',
        label: 'Idaho',
        manager: 3,
    },
    {
        value: 'IL',
        label: 'Illinois',
        manager: 12,
    },
    {
        value: 'IN',
        label: 'Indiana',
        manager: 13,
    },
    {
        value: 'IA',
        label: 'Iowa',
        manager: 12,
    },
    {
        value: 'KS',
        label: 'Kansas',
        manager: 14,
    },
    {
        value: 'KY',
        label: 'Kentucky',
        manager: 13,
    },
    {
        value: 'LA',
        label: 'Louisiana',
        manager: 4,
    },
    {
        value: 'ME',
        label: 'Maine',
        manager: 7,
    },
    {
        value: 'MD',
        label: 'Maryland',
        manager: 8,
    },
    {
        value: 'MA',
        label: 'Massachusetts',
        manager: 7,
    },
    {
        value: 'MI',
        label: 'Michigan',
        manager: 13,
    },
    {
        value: 'MN',
        label: 'Minnesota',
        manager: 12,
    },
    {
        value: 'MS',
        label: 'Mississippi',
        manager: 1,
    },
    {
        value: 'MO',
        label: 'Missouri',
        manager: 12,
        regions: [
            {
                value: 'West MO',
                label: 'West MO',
                manager: 14,
            },
        ],
    },
    {
        value: 'MT',
        label: 'Montana',
        manager: 3,
    },
    {
        value: 'NE',
        label: 'Nebraska',
        manager: 12,
        regions: [
            {
                value: 'Omaha',
                label: 'Omaha',
                manager: 12,
            },
        ],
    },
    {
        value: 'NV',
        label: 'Nevada',
        manager: 3,
    },
    {
        value: 'NH',
        label: 'New Hampshire',
        manager: 7,
    },
    {
        value: 'NJ',
        label: 'New Jersey',
        manager: 6,
        regions: [
            {
                value: 'Central Jersey',
                label: 'Central Jersey',
                manager: 8,
            },
            {
                value: 'South Jersey',
                label: 'South Jersey',
                manager: 8,
            },
        ],
    },
    {
        value: 'NM',
        label: 'New Mexico',
        manager: 3,
    },
    {
        value: 'NY',
        label: 'New York',
        manager: 6,
        regions: [
            {
                value: 'Metro NY',
                label: 'Metro NY',
                manager: 6,
            },
            {
                value: 'Upstate NY',
                label: 'Upstate NY',
                manager: 7,
            },
        ],
    },
    {
        value: 'NC',
        label: 'North Carolina',
        manager: 9,
    },
    {
        value: 'ND',
        label: 'North Dakota',
        manager: 12,
    },
    {
        value: 'OH',
        label: 'Ohio',
        manager: 13,
        regions: [
            {
                value: 'Cincinnati',
                label: 'Cincinnati',
                manager: 13,
            },
            {
                value: 'Cleveland',
                label: 'Cleveland',
                manager: 13,
            },
        ],
    },
    {
        value: 'OK',
        label: 'Oklahoma',
        manager: 14,
    },
    {
        value: 'OR',
        label: 'Oregon',
        manager: 2,
    },
    {
        value: 'PA',
        label: 'Pennsylvania',
        manager: 13,
        regions: [
            {
                value: 'West PA',
                label: 'West PA',
                manager: 13,
            },
            {
                value: 'East PA',
                label: 'East PA',
                manager: 8,
            },
            {
                value: 'Philadelphia',
                label: 'Philadelphia',
                manager: 8,
            },
        ],
    },
    {
        value: 'PR',
        label: 'Puerto Rico',
        manager: 1,
    },
    {
        value: 'RI',
        label: 'Rhode Island',
        manager: 7,
    },
    {
        value: 'SC',
        label: 'South Carolina',
        manager: 9,
    },
    {
        value: 'SD',
        label: 'South Dakota',
        manager: 12,
    },
    {
        value: 'TN',
        label: 'Tennessee',
        manager: 4,
        regions: [
            {
                value: 'West TN',
                label: 'West TN',
                manager: 4,
            },
            {
                value: 'East TN',
                label: 'East TN',
                manager: 9,
            },
            {
                value: 'Memphis',
                label: 'Memphis',
                manager: 4,
            },
            {
                value: 'Knoxville',
                label: 'Knoxville',
                manager: 9,
            },
            {
                value: 'Nashville',
                label: 'Nashville',
                manager: 9,
            },
        ],
    },
    {
        value: 'TX',
        label: 'Texas',
        manager: 14,
        regions: [
            {
                value: 'North Texas',
                label: 'North Texas',
                manager: 14,
            },
            {
                value: 'South Texas',
                label: 'South Texas',
                manager: 4,
            },
            {
                value: 'Houston',
                label: 'Houston',
                manager: 4,
            },
            {
                value: 'Austin',
                label: 'Austin',
                manager: 4,
            },
            {
                value: 'Dallas',
                label: 'Dallas',
                manager: 14,
            },
            {
                value: 'Lubbock',
                label: 'Lubbock',
                manager: 14,
            },
        ],
    },
    {
        value: 'UT',
        label: 'Utah',
        manager: 3,
    },
    {
        value: 'VT',
        label: 'Vermont',
        manager: 7,
    },
    {
        value: 'VA',
        label: 'Virginia',
        manager: 9,
    },
    {
        value: 'WA',
        label: 'Washington',
        manager: 2,
    },
    {
        value: 'WV',
        label: 'West Virginia',
        manager: 9,
    },
    {
        value: 'WI',
        label: 'Wisconsin',
        manager: 12,
    },
    {
        value: 'WY',
        label: 'Wyoming',
        manager: 13,
    },
]

export const regionalManagers = {
    1: { name: 'Gina Phillips', email: 'gphillips@kensfoods.com' },
    2: { name: 'Arin Perrott', email: 'aperrott@kensfoods.com' },
    3: { name: 'Mark Van Benschoten', email: 'mvanbenschoten@kensfoods.com' },
    4: { name: 'Alicia Clark', email: 'aclark@kensfoods.com' },
    5: { name: 'Jenifer Sabins', email: 'jsabins@kensfoods.com' },
    6: { name: 'Steve Meier', email: 'smeier@kensfoods.com' },
    7: { name: 'Andy Sealund', email: 'asealund@kensfoods.com' },
    8: { name: 'Brendan Downs', email: 'bdowns@kensfoods.com' },
    9: { name: 'Kendall Hardwick', email: 'khardwick@kensfoods.com' },
    10: { name: 'Greg Rodeck', email: 'grodeck@kensfoods.com' },
    11: { name: 'Gina Infante', email: 'ginfante@kensfoods.com' },
    12: { name: 'Brian Klimas', email: 'bklimas@kensfoods.com' },
    13: { name: 'Corbin Stockman', email: 'cstockman@kensfoods.com' },
    14: { name: 'Luis Fuerte', email: 'lfuerte@kensfoods.com' },
}
