import { contactRegions } from '../../../config'

export const getRegionalManagerId = (state, region) => {
    let managerId

    const targetStateConf = contactRegions.find((s) => s.value === state)

    if (!targetStateConf) {
        return
    }

    managerId = targetStateConf.manager

    // if region is 'other' use manager from state
    if (targetStateConf.regions && region && region !== 'other') {
        const targetRegionConf = targetStateConf.regions.find(
            (r) => r.value === region
        )

        if (targetRegionConf && targetRegionConf.manager) {
            managerId = targetRegionConf.manager
        }
    }

    return managerId
}
