import { takeLatest, put, call } from 'redux-saga/effects'

import ApiClient from '../../../helpers/api'
import { actionTypes as types } from './constants'

const fetchAllProducts = function* () {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/products/faq`
        )
        yield put({
            type: types.FAQ_FETCH_ALL_PRODUCTS_SUCCESS,
            payload: apiData,
        })
    } catch (err) {
        console.error('[faq fetchAllFaqProduct] error: ', err)
        yield put({
            type: types.FAQ_SUBMIT_PURCHASE_FORM_FAILED,
            message: err.message,
        })
    }
}

const submitPurchaseFormSaga = function* (action) {
    try {
        yield ApiClient.post('/api/faq/purchase-form', action.payload)

        yield put({ type: types.FAQ_SUBMIT_PURCHASE_FORM_SUCCESS })
    } catch (error) {
        yield put({
            type: types.FAQ_SUBMIT_PURCHASE_FORM_FAILED,
            error: error.message || 'Oops, somehing went wrong!',
        })
    }
}

export default [
    takeLatest(types.FAQ_FETCH_ALL_PRODUCTS_START, fetchAllProducts),
    takeLatest(types.FAQ_SUBMIT_PURCHASE_FORM_START, submitPurchaseFormSaga),
]
