import * as yup from 'yup'

export const DateSchema = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    excludeEmptyString: true,
    message: 'This field must be a valid date',
})

export const NumericSchema = yup
    .string()
    .test('isNumeric', 'isNumeric', function (value) {
        const { path, createError } = this

        if (value === '' || value === undefined) {
            return new createError({
                path,
                message: 'This field is required',
            })
        } else if (!/^\d+$/.test(value)) {
            return new createError({
                path,
                message: 'This field is invalid',
            })
        }

        return true
    })

const ProductCodeError = 'Item numbers must contain at least 12 to 20 characters'

export const ProductCodeSchema = yup
    .string()
    .min(12, ProductCodeError)
    .max(20, ProductCodeError)
