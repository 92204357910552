import React from 'react'

import FieldInfo from '../../../../../components/forms/form-parts/raw/FieldInfo'
import YearFieldOriginal from '../../../../../components/forms/form-fields/YearField'
import yearImage from '../../../../../assets/img/faq/year-manufacture.jpg'

const YearField = (props) => {
    return (
        <FieldInfo
            info="Enter the two digit year from the MFG code."
            modal={{
                modalLinkText: 'See year of manufacture example',
                modalContent: 
                <>
                    <h2>Example image and instructions</h2>
                    <p><img className="small-img" src={yearImage} alt="Year of manufacture example" /></p>
                    <span className="descr">The year is the last two digits from the second row of MFG code <span className="no-wrap">(e.g. 23).</span></span>
                </>
            }}
        >
            <YearFieldOriginal {...props} />
        </FieldInfo>
    )
}
export default YearField
