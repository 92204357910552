import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchAllProducts } from '../../store/actions'

const propTypes = {
    children: PropTypes.node,
}

const ServiceWrapper = ({
    children,
    // store
    products,
    productsLoading,
    productsError,
    fetchAllProducts,
}) => {

    useEffect(() => {
        if (!products && !productsLoading && !productsLoading) {
            fetchAllProducts()
        }
    }, [products, productsLoading, productsError, fetchAllProducts])

    return <section>{children}</section>
}

ServiceWrapper.propTypes = propTypes

const mapStateToProps = (state) => ({
    products: state.faq.products.list,
    productsLoading: state.faq.products.loading,
    productsError: state.faq.products.error,
})

export default connect(mapStateToProps, { fetchAllProducts })(ServiceWrapper)
