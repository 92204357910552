import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

const FieldWrapperRaw = ({ errorMessage, className, children }) => {
    return (
        <div title={errorMessage} className={classNames(className)}>
            {children}
        </div>
    )
}

FieldWrapperRaw.propTypes = propTypes

export default FieldWrapperRaw
