import React from 'react'

import SelectField from './SelectField'
import StateFieldRaw from './raw/StateField'

const StateField = (props) => {
    return <StateFieldRaw {...props} as={SelectField} />
}

export default StateField
