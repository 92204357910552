import { all, takeLatest, put, call } from 'redux-saga/effects';
import Qs from 'qs';

import {
    LOAD_LOOKUP_DATA,
    LOAD_LOOKUP_DATA_COMPLETE
} from './actions/types';
import { ADD_ERROR } from './actions/types'

import homeSagas from './containers/home/saga';
import productSagas from './containers/products/saga';
import recipeSagas from './containers/recipes/saga';
import plusOneSagas from './containers/plus-one/saga';
import contactSagas from './containers/contact/saga';
import sampleSagas from './containers/get-sample/saga';
import reCaptchaSagas from './components/re-captcha/saga';
import searchResultSagas from './containers/search-result/saga';
import pizzaExpoSagas from './containers/pizzaexpo/saga';
import faqSagas from './containers/faq/store/saga';

import ApiClient from './helpers/api';

export default function* () {
    yield all([
        takeLatest(LOAD_LOOKUP_DATA, loadLookupData),
        ...homeSagas,
        ...productSagas,
        ...recipeSagas,
        ...contactSagas,
        ...sampleSagas,
        ...plusOneSagas,
        ...reCaptchaSagas,
        ...searchResultSagas,
        ...pizzaExpoSagas,
        ...faqSagas,
    ])
};


const loadLookupData = function* () {
    try {

        const query = Qs.stringify({dataset: ['flavors', 'productLines', 'packagings', 'dishes', 'ingredients', 'trends', 'courses', 'uoms']});
        const apiData = yield call([ApiClient, ApiClient.get], `/api/dxp-data?${query}`);

        const result = {};

        if (apiData.data && Array.isArray(apiData.data)) {
            for (const ds of apiData.data) {
                result[ds.dataset] = ds.data;
            }
        }

        yield put({ type: LOAD_LOOKUP_DATA_COMPLETE, payload: result });
    }
    catch (err) {
        console.log(err);
        yield put({ type: ADD_ERROR, message: err.message });
    }
};
