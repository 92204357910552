import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import FieldWrapper from '../../../../../components/forms/form-parts/FieldWrapper'
import Form from '../../forms/form-parts/Form'
import FormActions from '../../forms/form-parts/FormActions'
import ProductsField from '../../forms/form-fields/ProductsField'
import JulianDateField from '../../forms/form-fields/JulianDateField'
import YearField from '../../forms/form-fields/YearField'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ProductLinks from '../../service-parts/product-links'
import ServiceInfo from '../../service-parts/service-info'
import { getFullProductName } from '../../../utils/helpers'
import { calculateExpirationDate } from './helpers'
import defaultValues from './defaultValues'
import schema from './schema'
import format from 'date-fns/format'

const ExpirationDateService = () => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    })

    const {
        reset,
        handleSubmit,
        watch,
        formState: { errors, dirtyFields, isValid },
    } = methods

    const [formFreeze, setFormFreeze] = useState(false)

    const [result, setResult] = useState(null)

    const [error, setError] = useState(null)

    const activeProduct = watch('product')

    const submitForm = (formValues) => {
        setError(null)

        let error
        let expDate

        const { product, julianDate, year } = formValues

        try {
            expDate = calculateExpirationDate({
                shelfLife: product.shelfLife,
                julianDate,
                year,
            })
        } catch (e) {
            error = e
        }

        if (expDate) {
            setFormFreeze(true)

            const productName = getFullProductName(product)
            const productID = product.id || ''

            setResult({
                title: `${productName}`,
                value: format(expDate, 'MM/dd/yyyy'),
                url: `/products/${productID}`,
            })
        } else if (error) {
            setError(error.message)
        }
    }

    const resetForm = (e) => {
        e && e.preventDefault()

        setFormFreeze(false)

        setResult(null)

        reset()
    }

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                To determine the expiration date, please follow the instructions
                below:
            </ServiceSubhead>
            <Form methods={methods} onSubmit={handleSubmit(submitForm)}>
                <FieldWrapper name="product">
                    <ProductsField name="product" disabled={formFreeze} />
                </FieldWrapper>
                {dirtyFields['product'] && !errors['product'] ? (
                    <FieldWrapper name="julianDate">
                        <JulianDateField
                            name="julianDate"
                            placeholder="Julian Code"
                            disabled={formFreeze}
                        />
                    </FieldWrapper>
                ) : null}
                {dirtyFields['julianDate'] && !errors['julianDate'] ? (
                    <FieldWrapper name="year">
                        <YearField
                            name="year"
                            maxLength="2"
                            placeholder="Year of manufacture"
                            disabled={formFreeze}
                        />
                    </FieldWrapper>
                ) : null}
                {isValid ? (
                    <FormActions
                        onClear={resetForm}
                        disabled={formFreeze}
                        submitLabel="Calculate Expiration Date"
                    />
                ) : null}
            </Form>
            {error ? <p>{error}</p> : null}
            <ServiceResult
                result={
                    result ? (
                        <>
                            <span>The expiration date for {result.title}:</span>
                            <h3>{result.value}</h3>
                        </>
                    ) : null
                }
                product={activeProduct}
            />
            <ProductLinks
                result={result ? result : null}
                product={activeProduct}
            />
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default ExpirationDateService
