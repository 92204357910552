import React from 'react'

import InputField from './InputField'

const JulianDateFieldRaw = ({ as, ...inputProps }) => {
    const Component = as ? as : InputField

    return <Component {...inputProps} numeric maxLength="3" />
}

export default JulianDateFieldRaw
