import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node,
}

const Form = ({ className, methods, onSubmit, children }) => {
    return (
        <div className={classNames('form-container', className)}>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>{children}</form>
            </FormProvider>
        </div>
    )
}

Form.propTypes = propTypes

export default Form
