import React from 'react'

import SelectField from './SelectField'
import ProductsFieldRaw from './raw/ProductsField'

const ProductsField = (props) => {
    return <ProductsFieldRaw {...props} as={SelectField} />
}

export default ProductsField
