import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    message: PropTypes.string,
}

const InputErrorRaw = ({ message }) => {
    if (!message) {
        return null
    }

    return <div className="error">{message}</div>
}

InputErrorRaw.propTypes = propTypes

export default InputErrorRaw
