import React from 'react'
import { connect } from 'react-redux'

import FieldInfo from '../../../../../components/forms/form-parts/raw/FieldInfo'
import ProductsFieldOriginal from '../../../../../components/forms/form-fields/ProductsField'

const ProductsField = ({
    products,
    placeholder,
    productsLoading,
    productsError,
    ...props
}) => {
    return (
        <FieldInfo info="Select from drop-down menu, enter the product name, or the three/four-digit code from the lower left side of the product label.">
            <ProductsFieldOriginal
                {...props}
                placeholder={
                    productsError
                        ? 'Error. Reload the page.'
                        : placeholder || 'Select product / Enter product code'
                }
                optionsLoading={productsLoading}
                options={products || []}
            />
        </FieldInfo>
    )
}

const mapStateToProps = (state) => ({
    products: state.faq.products.list,
    productsLoading: state.faq.products.loading,
    productsError: state.faq.products.error,
})

export default connect(mapStateToProps)(ProductsField)
