const defaultValues = {
    state: '',
    hasRegion: false,
    region: '',
    city: '',
    products: [],
    company: '',
    fullName: '',
    email: '',
    phone: '',
    message: '',
}

export default defaultValues
