import React from 'react'
import _get from 'lodash/get'
import { useFormContext } from 'react-hook-form'

import InputErrorRaw from './raw/InputError'

const InputError = ({ name }) => {
    const {
        formState: { errors },
    } = useFormContext()

    const message = _get(errors, `${name}.message`)

    return <InputErrorRaw message={message} />
}
export default InputError
