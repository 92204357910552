import React from 'react'

import InputFieldRaw from './InputField'

export const YearFieldRaw = ({ as, ...inputProps }) => {
    const Component = as ? as : InputFieldRaw

    return <Component maxLength="4" {...inputProps} numeric />
}

export default YearFieldRaw
