import React, { forwardRef } from 'react'
import Select from 'react-select'
import classNames from 'classnames'

const SelectFieldRaw = forwardRef(
    (
        {
            className,
            as,
            getCurrentValue,
            handleOnChange,
            name,
            onChange,
            options,
            placeholder,
            disabled,
            isMulti,
            value,
            ...rest
        },
        ref
    ) => {
        const SelectComponent = as || Select

        const formatCreateLabel = (value) => {
            return `Add "${value}"`
        }

        const isValidNewOption = (value) => {
            const isValidLengh =
                value && value.length >= 3 && value.length < 600

            if (!isValidLengh) {
                return false
            }

            const exists = options.find((o) => {
                return o.value === value
            })

            if (exists) {
                return false
            }

            return true
        }

        const getValue = getCurrentValue
            ? () => getCurrentValue(value)
            : () => {
                  if (!value) {
                      return ''
                  }

                  let nextValue = options.find((o) => {
                      return o.value === value
                  })

                  if (!nextValue) {
                      // value was added from input
                      nextValue = {
                          label: value,
                          value: value,
                      }
                  }

                  return nextValue
              }

        const getValueMulti = getCurrentValue
            ? () => getCurrentValue(value)
            : () => {
                  if (!value) {
                      return []
                  }

                  let nextValues = value.map((v) => {
                      const targetOption = options.find((o) => {
                          return o.value === v
                      })

                      return (
                          targetOption || {
                              label: v,
                              value: v,
                          }
                      )
                  })

                  return nextValues
              }

        const _handleOnChange = handleOnChange
            ? (option) => handleOnChange(option, onChange)
            : (option) => {
                  onChange(option ? option.value : '')
              }

        const handleOnChangeMulti = handleOnChange
            ? (option) => handleOnChange(option, onChange)
            : (options) => {
                  const list = (options || []).map((o) => o.value)

                  onChange(list)
              }

        return (
            <SelectComponent
                {...rest}
                inputRef={ref}
                name={name}
                isSearchable
                isDisabled={disabled}
                placeholder={placeholder || 'Select...'}
                options={options}
                className={classNames('custom-select', className)}
                classNamePrefix="form"
                isMulti={isMulti}
                value={isMulti ? getValueMulti() : getValue()}
                onChange={isMulti ? handleOnChangeMulti : _handleOnChange}
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewOption}
            />
        )
    }
)

export default SelectFieldRaw
