import React, { lazy, Suspense, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Modal = lazy(() => import('../../../modal/Modal'))

const propTypes = {
    className: PropTypes.string,
    info: PropTypes.string,
    modal: PropTypes.shape({
        modalLinkText: PropTypes.string,
        modalContent: PropTypes.node,
    }),
    children: PropTypes.node,
}

const FieldInfo = ({ className, info, modal = {}, children }) => {
    const { modalLinkText, modalContent } = modal

    const [modalVisible, setModalVisible] = useState(false)

    return (
        <div className={classNames('field-info', className)}>
            {children}

            {info ?
                <div className="circle">
                    i
                    <span className="tooltip">{info}</span>
                </div>
            : null}

            {modalLinkText && modalContent ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    href="#"
                    onClick={(e) => {
                        e && e.preventDefault()

                        setModalVisible(true)
                    }}
                    className="modal-link"
                >
                    {modalLinkText}
                </a>
            ) : null}

            {modalLinkText && modalContent && (
                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <Modal
                        visible={modalVisible}
                        onClose={(e) => {
                            e && e.preventDefault()

                            setModalVisible(false)
                        }}
                    >
                        <div className="modal-content">{modalContent}</div>
                    </Modal>
                </Suspense>
            )}
        </div>
    )
}

FieldInfo.propTypes = propTypes

export default FieldInfo
