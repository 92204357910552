import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    children: PropTypes.node,
}

const ServiceSubhead = ({ children }) => {
    return <div className="descr">{children}</div>
}

ServiceSubhead.propTypes = propTypes

export default ServiceSubhead
