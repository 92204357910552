import React from 'react'

import InputField from './InputField'

const ProductCodeFieldRaw = ({ as, ...inputProps }) => {
    const Component = as ? as : InputField

    return <Component {...inputProps} maxLength="20" />
}

export default ProductCodeFieldRaw
