import * as yup from 'yup'

import yupMessages from '../../../../../helpers/yup/yup-messages'

yupMessages()

const NumberSchema = yup
    .number()
    .typeError('This field must be a valid number')
    .integer()

// limits for year of manufacture
const maxYear = new Date().getFullYear().toString().slice(-2)
const minYear = maxYear - 10

const schema = yup.object({
    product: yup.object().required(),
    julianDate: NumberSchema.min(1).max(366).required(),
    year: NumberSchema.min(minYear).max(maxYear).required(),
})

export default schema
