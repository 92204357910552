import React, { forwardRef } from 'react'
import classNames from 'classnames'

// https://www.react-hook-form.com/advanced-usage/#Transform
const transformNumeric = (value = '', { floating }) => {
    const regex = floating ? /[^\d.]|(?<=\..*)\./g : /\D/g

    return String(value).replace(regex, '')
}

const InputFieldRaw = forwardRef(
    (
        {
            type = 'text',
            className,
            name,
            disabled,
            numeric,
            floating,
            value,
            onChange,
            children,
            ...inputProps
        },
        ref
    ) => {
        return (
            <input
                {...inputProps}
                ref={ref}
                type={type}
                className={classNames('custom-input', className)}
                disabled={disabled}
                onChange={(e) => {
                    if (numeric) {
                        return onChange(
                            transformNumeric(e.target.value, {
                                floating,
                            })
                        )
                    }

                    onChange(e)
                }}
                value={
                    numeric
                        ? transformNumeric(value, {
                              floating,
                          })
                        : value
                }
            />
        )
    }
)

export default InputFieldRaw
