import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

const Result = ({ className, children }) => {
    return <div className={classNames('result', className)}>{children}</div>
}

Result.propTypes = propTypes

export default Result
