import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { prepareGTMDataset } from '../../helpers/googleTagManager';

import './footer.scss';

const footerMenu = [
    {
        link: '/privacy',
        title: 'Privacy policy',
        gtm: 'data-gtm-privacy-policy-link-a'
    },
    {
        link: '/terms',
        title: 'Terms of use',
        gtm: 'data-gtm-terms-of-use-link-a'
    },
    {
        link: '/whats-hot',
        title: 'What\'s hot now',
        class: 'no-desktop',
        gtm: 'data-gtm-whats-hot-now-link-a'
    },
    {
        link: '/contact',
        title: 'Contact us',
        gtm: 'data-gtm-contact-us-link-a'
    },
    {
        link: '/faq',
        title: 'FAQ',
        gtm: 'data-gtm-faq-link-a'
    }
];

class Footer extends PureComponent {

    getFooterNav() {
        return (
            footerMenu.map((item, i) => {
                return (
                    <NavLink key={`footer-link-${i}`} className={item.class} to={item.link} {...prepareGTMDataset(item.gtm)}>{item.title}</NavLink>
                )
            })
        );
    };

    render() {

        return (
            <footer id="no-print">
                <span className="legal">&copy; {(new Date().getFullYear())} Ken's Foods, LLC</span>
                <span className="footer-nav">{this.getFooterNav()}</span>
                <a data-gtm-resources-link-a href="https://sales.kensfoodservice.com" target="_blank" rel="noopener noreferrer">Resources</a>
                <a data-gtm-careers-link-a href="https://jobs.kensfoods.com" target="_blank" rel="noopener noreferrer">Careers</a>
                <span className="trademark">Ken&rsquo;s The First Name in Flavor</span>
            </footer>
        );
    }
}

export default Footer;
