import * as yup from 'yup'

import yupMessages from '../../../../../../helpers/yup/yup-messages'
import { ProductCodeSchema } from '../../../../../../helpers/yup/yup-schemas'

yupMessages()

const schema = yup
    .object({
        product: yup
            .mixed()
            .test(
                'is-string-or-object',
                'Must be string or object',
                (value) => {
                    return value === '' || typeof value === 'object'
                }
            ),
        upc: yup.string().when('product', {
            is: (val) => !val,
            then: () => ProductCodeSchema,
            otherwise: () => yup.string().optional(),
        }),
    })
    .test(
        'at-least-one-field',
        'At least one field must be filled',
        (value) => {
            return value.product !== '' || value.upc !== ''
        }
    )

export default schema
