import React from 'react'

import FieldInfo from '../../../../../components/forms/form-parts/raw/FieldInfo'
import JulianDateFieldOriginal from '../../../../../components/forms/form-fields/JulianDateField'
import julianImage from '../../../../../assets/img/faq/julian-code.jpg'

const JulianDateField = (props) => {
    return (
        <FieldInfo
            info="Enter the first set of digits from the MFG code."
            modal={{
                modalLinkText: 'See Julian code example',
                modalContent:
                    <>
                        <h2>Example image and instructions</h2>
                        <p><img className="small-img" src={julianImage} alt="Julian example" /></p>
                        <span className="descr">Enter the first set of digits from the MFG code <span className="no-wrap">(e.g. 219).</span></span>
                    </>
            }}
        >
            <JulianDateFieldOriginal {...props} />
        </FieldInfo>
    )
}
export default JulianDateField
