import React from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

import { handleImgError } from '../../../../../../helpers/utils'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    product: PropTypes.object,
}

const ProductImage = ({ className, product }) => {
    return (
        <div className={classNames('product-image', className)}>
            <img
                src={_get(product, 'images.websiteThumbnail')}
                alt={_get(product, 'name')}
                onError={handleImgError}
            />
        </div>
    )
}

ProductImage.propTypes = propTypes

export default ProductImage
