import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    result: PropTypes.object,
}

const ProductLinks = ({ className, result, product }) => {
    return result ? (
        <div className={classNames('form-actions', className)}>
            <Link to={result.url} target="_blank">
                Visit product page
            </Link>
            <Link
                to={`/faq/where-purchase${
                    product ? `?product=${product._id}` : ''
                }`}
            >
                Where to Buy
            </Link>
        </div>
    ) : null
}

ProductLinks.propTypes = propTypes

export default ProductLinks
