import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PageNotFound from '../page-not-found/PageNotFound'

import routes from '../../routes.json'

const FaqRouter = () => {
    const faq = routes.find(({ path, routes }) => path === '/faq' && routes)

    if (!faq) {
        throw new Error('Cannot find route declaration for FAQ routes')
    }

    return (
        <Switch>
            {faq.routes.map(({ path, component, props }, index) => {
                if (!path) {
                    return <Route key={index} component={PageNotFound} />
                }

                const RouteComponent = require(`${component}.jsx`).default
                return (
                    <Route
                        key={index}
                        path={path}
                        component={RouteComponent}
                        {...props}
                    />
                )
            })}
        </Switch>
    )
}

export default FaqRouter
