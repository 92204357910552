import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    src: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const Example = ({ src, description }) => {
    return (
        <div className="example">
            <p><img src={src} alt="" /></p>
            <p>{description}</p>
        </div>
    )
}

Example.propTypes = propTypes

export default Example
