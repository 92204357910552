import React from 'react'

import InputField from './InputField'
import YearFieldRaw from './raw/YearField'

const YearField = (props) => {
    return <YearFieldRaw {...props} as={InputField} />
}

export default YearField
