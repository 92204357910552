import React, { useCallback, useState } from 'react'
import _get from 'lodash/get'
import { getFullProductName } from '../../../utils/helpers'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ServiceInfo from '../../service-parts/service-info'
import ProductLinks from '../../service-parts/product-links'
import FindProductForm from '../../forms/shared-forms/find-product-form'

import glutenIcon from '../../../../../assets/img/gluten-free-icon.png'

const GlutenFreeService = () => {
    const [result, setResult] = useState(null)

    const [activeProduct, setActiveProduct] = useState(null)

    const onChange = useCallback((p) => {
        setActiveProduct(p)
    }, [])

    const onSubmit = (product) => {
        const productName = getFullProductName(product)
        const productID = product.id || ''

        // Yes/No
        const glutenStatus = _get(product, 'marketing.gluten', '')

        setResult({
            title: `${productName}`,
            value:
                glutenStatus.toLowerCase() === 'yes' ? (
                    <span>
                        Gluten-Free <img src={glutenIcon} alt="gluten" />
                    </span>
                ) : (
                    'Not Gluten-Free'
                ),
            url: `/products/${productID}`,
        })

        setActiveProduct(product)
    }

    const onReset = () => {
        setResult()

        setActiveProduct()
    }

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <>
                    Enter information into <strong>one</strong> of the following
                    fields for the Gluten Status of our products:
                </>
            </ServiceSubhead>
            <FindProductForm
                onSubmit={onSubmit}
                onChange={onChange}
                onReset={onReset}
                submitButtonLabel="Get Gluten-Free Status"
            />
            <ServiceResult
                result={
                    result ? (
                        <>
                            <span>{result.title} is:</span>
                            <h3>{result.value}</h3>
                        </>
                    ) : null
                }
                product={activeProduct}
            />
            <ProductLinks
                result={result ? result : null}
                product={activeProduct}
            />
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default GlutenFreeService
