import React from 'react'
import classNames from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'

import InputError from '../form-parts/InputError'
import InputFieldRaw from './raw/InputField'

const InputField = ({
    type = 'text',
    name,
    noError,
    children,
    ...inputProps
}) => {
    const methods = useFormContext()

    const isHiddenType = type === 'hidden'

    return (
        <div className={classNames('field-wrapper', { hidden: isHiddenType })}>
            <Controller
                name={name}
                control={methods.control}
                render={({ field }) => {
                    return (
                        <InputFieldRaw type={type} {...inputProps} {...field} />
                    )
                }}
            />
            {!isHiddenType && children}
            {!noError && !isHiddenType ? <InputError name={name} /> : null}
        </div>
    )
}

export default InputField
