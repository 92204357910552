import React, { useCallback, useState } from 'react'

import { getFullProductName } from '../../../utils/helpers'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ServiceInfo from '../../service-parts/service-info'
import ProductLinks from '../../service-parts/product-links'
import FindProductForm from '../../forms/shared-forms/find-product-form'

const ProductActiveService = () => {
    const [result, setResult] = useState(null)

    const [activeProduct, setActiveProduct] = useState(null)
    const [isActive, setIsActive] = useState(null)

    const onChange = useCallback((p) => {
        setActiveProduct(p)
    }, [])

    const onSubmit = (product) => {
        const productName = getFullProductName(product)
        const productID = product.id || ''
        const status = product.status || ''
        const isAvailable = status.toLowerCase() === 'website active'

        setResult({
            title: `${productName}`,
            value: isAvailable ? 'Available' : null,
            url: `/products/${productID}`,
        })

        isAvailable && setIsActive(true)

        setActiveProduct(product)
    }

    const onReset = () => {
        setResult()

        setActiveProduct()
        setIsActive()
    }

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <>
                    Enter information into <strong>one</strong> of the following
                    fields to check product availability. If you do not find
                    what you are looking for in the drop-down, please contact
                    use at <span className="no-wrap">508-9480-4724</span>{' '}
                    between 8:30 AM - 5:00 PM EST M-F.
                </>
            </ServiceSubhead>
            <FindProductForm
                onSubmit={onSubmit}
                onChange={onChange}
                onReset={onReset}
                submitButtonLabel="Get Product Status"
            />
            <ServiceResult
                result={
                    result ? (
                        <>
                            {result.value ? (
                                <span>{result.title} is:</span>
                            ) : null}
                            {result.value ? (
                                <h3>{result.value}</h3>
                            ) : (
                                `Contact us regarding the availability of ${result.title}`
                            )}
                        </>
                    ) : null
                }
                product={activeProduct}
                notActive={!isActive}
            />
            {isActive && (
                <ProductLinks
                    result={result ? result : null}
                    product={activeProduct}
                />
            )}
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default ProductActiveService
