import React, { PureComponent } from 'react'
import ServiceSubhead from '../../components/service-parts/ServiceSubhead'

class HomeScreen extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="faq-body">
                <div className="service-component">
                    <h2>Find the information you need</h2>
                    <ServiceSubhead>
                        Select a tool to check allergens, gluten-free status, kosher certification, manufacturing codes, or product availability and more.
                    </ServiceSubhead>
                    <p>Simply pick the tool that best fits your question and follow the prompts.</p>
                </div>
            </div>
        )
    }
}

export default HomeScreen
