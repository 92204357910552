import React from 'react'

import InputField from './InputField'
import ProductCodeFieldRaw from './raw/ProductCodeField'

export const ProductCodeField = (props) => {
    return <ProductCodeFieldRaw {...props} as={InputField} />
}

export default ProductCodeField
