import React, { PureComponent } from 'react'

class NotFoundScreen extends PureComponent {
    // componentDidMount() {
    //     window.scrollTo(0, 0)
    // }

    render() {
        return <div>NotFoundScreen</div>
    }
}

export default NotFoundScreen
