import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'

import { fetchAllProducts } from '../../../../containers/products/actions'
import SelectFieldRaw from './SelectField'

const getCurrentValue = (v) => v

const getCurrentValueMulti = (v) => {
    if (!v) {
        return []
    }

    return v
}

const ProductsFieldRaw = ({
    placeholder,
    loading,
    disabled,
    as,
    isMulti,

    // store
    allProducts,
    productsLoading,
    fetchAllProducts,

    // override for default store
    optionsLoading,
    options,

    ...selectProps
}) => {
    useEffect(() => {
        if (options) {
            return
        }

        if (!allProducts.length && !productsLoading) {
            fetchAllProducts()
        }
    }, [options, allProducts.length, productsLoading, fetchAllProducts])

    const Component = as ? as : SelectFieldRaw

    return (
        <Component
            {...selectProps}
            isMulti={isMulti}
            placeholder={
                placeholder || 'Select product / Enter product code'
            }
            options={sortBy(options || allProducts, 'name')}
            getOptionLabel={(i) =>
                `${i.name || ''} ${i.code ? `[${i.code}]` : ''}`
            }
            getOptionValue={(i) => i.id}
            getCurrentValue={isMulti ? getCurrentValueMulti : getCurrentValue}
            handleOnChange={(v, onChange) => {
                onChange(v)
            }}
            deffaultValue={isMulti ? [] : ''}
            // store loading
            isLoading={optionsLoading || productsLoading}
            // form loading
            disabled={disabled || loading}
        />
    )
}

const mapStateToProps = (state) => ({
    allProducts: state.products.all,
    productsLoading: state.products.paging.loading,
})

export default connect(mapStateToProps, { fetchAllProducts })(ProductsFieldRaw)
