import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

class ServiceScreen extends Component {
    // componentDidMount() {
    //     window.scrollTo(0, 0)
    // }

    render() {
        const { className, title, children } = this.props

        return (
            <div className={classNames('faq-body', className)}>
                <div className="service-component">
                    <h2>{title}</h2>
                    {children}
                </div>
            </div>
        )
    }
}

ServiceScreen.propTypes = propTypes

export default ServiceScreen
