import _cloneDeep from 'lodash/cloneDeep'
import { actionTypes as types } from './constants'

const initialState = {
    products: {
        list: null,
        loading: false,
        error: '',
    },
    purchaseForm: {
        success: false,
        loading: false,
        error: '',
    },
}

export default (state = initialState, action) => {
    let updatedState = _cloneDeep(state)

    switch (action.type) {
        case types.FAQ_FETCH_ALL_PRODUCTS_START:
            updatedState.products.loading = true
            updatedState.products.error = ''
            break
        case types.FAQ_FETCH_ALL_PRODUCTS_SUCCESS:
            updatedState.products.list = action.payload.data || []
            updatedState.products.loading = false
            break
        case types.FAQ_FETCH_ALL_PRODUCTS_FAILED:
            updatedState.products.loading = false
            updatedState.products.error = action.error
            break
        case types.FAQ_SUBMIT_PURCHASE_FORM_START:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                success: false,
                error: '',
                loading: true,
            }
            break
        case types.FAQ_SUBMIT_PURCHASE_FORM_SUCCESS:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                success: true,
                loading: false,
            }
            break
        case types.FAQ_SUBMIT_PURCHASE_FORM_FAILED:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                loading: false,
                error: action.error,
            }
            break
        case types.FAQ_CLEAR_PURCHASE_FORM:
            updatedState.purchaseForm = {
                ...initialState.purchaseForm,
            }
            break
        default:
            break
    }

    return updatedState
}
