export const actionTypes = {
    FAQ_SUBMIT_PURCHASE_FORM_START: 'FAQ_SUBMIT_PURCHASE_FORM_START',
    FAQ_SUBMIT_PURCHASE_FORM_SUCCESS: 'FAQ_SUBMIT_PURCHASE_FORM_SUCCESS',
    FAQ_SUBMIT_PURCHASE_FORM_FAILED: 'FAQ_SUBMIT_PURCHASE_FORM_FAILED',
    FAQ_CLEAR_PURCHASE_FORM: 'FAQ_CLEAR_PURCHASE_FORM',

    FAQ_FETCH_ALL_PRODUCTS_START: 'FAQ_FETCH_ALL_PRODUCTS_START',
    FAQ_FETCH_ALL_PRODUCTS_SUCCESS: 'FAQ_FETCH_ALL_PRODUCTS_SUCCESS',
    FAQ_FETCH_ALL_PRODUCTS_FAILED: 'FAQ_FETCH_ALL_PRODUCTS_FAILED',
}
