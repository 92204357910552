import React, { Component, Suspense } from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import classNames from 'classnames'

import HomeScreen from './components/screens/HomeScreen'
import NotFoundScreen from './components/screens/NotFoundScreen'
import ServiceScreen from './components/screens/ServiceScreen'
import { services } from './config'

import './faq.scss'

class FaqPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            slug: this.getSlug(),
        }
    }

    // componentDidMount() {
    //     window.scrollTo(0, 0)
    // }

    componentDidUpdate() {
        const newSlug = this.getSlug()

        if (newSlug !== this.state.slug) {
            this.setState({ slug: newSlug })
        }
    }

    getSlug() {
        return get(this.props, 'match.params.slug')
    }

    render() {
        const { slug } = this.state

        const activeService = services.find((service) => service.slug === slug)

        const ServiceComponent = activeService ? activeService.component : null

        return (
            <>
                <div className="hero-wrapper faq">
                    <h1>Answers to Your Most Common Questions</h1>
                    <div className="descr">
                        At Ken&rsquo;s Foodservice, we know how important it is to have quick and accurate answers about our products. Whether you&rsquo;re curious about expiration dates, manufacturing codes, allergens, or product certifications like Kosher Dairy or Gluten Free, we&rsquo;ve got you covered. Explore our frequently asked questions below to find the information you need to confidently use and enjoy our products.
                    </div>
                </div>
            
                <div className="body-wrapper faq">
                    <ul className="faq-nav">
                        {services.map((service) => {
                            return (
                                <li
                                    key={service.slug}
                                    className={classNames({
                                        active: slug === service.slug,
                                    })}
                                >
                                    <Link to={`/faq/${service.slug}`}>
                                        {service.navTitle}
                                        <span className="tooltip">{service.tooltip}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>

                    {!slug ? <HomeScreen /> : null}
                    {slug && !activeService ? <NotFoundScreen /> : null}
                    {slug && activeService ? (
                        <ServiceScreen
                            className={activeService.slug}
                            title={activeService.title}
                        >
                            <Suspense
                                fallback={<p className="loading">Loading...</p>}
                            >
                                <ServiceComponent />
                            </Suspense>
                        </ServiceScreen>
                    ) : null}
                </div>
                <div className="float-info">
                    <div className="popup">
                        <div className="popup-header">Need Help?</div>
                        <div className="popup-body">
                            If you have any questions, please contact Ken&rsquo;s Foods for assistance:
                            <strong>508-948-4724</strong>
                            <span className="no-wrap">8:30 AM - 5:00 PM EST M-F</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default FaqPage
