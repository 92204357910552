import React from 'react'
import PropTypes from 'prop-types'

import Result from './components/Result'
import ProductImage from './components/ProductImage'
import classNames from 'classnames'

const propTypes = {
    className: PropTypes.string,
    result: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const ServiceResult = ({ className, result, product, notActive }) => {
    return result || product ? (
        <div className={classNames('service-result', className)}>
            {result ? <Result>{result}</Result> : <div></div>}
            {product ? <ProductImage className={notActive ? 'unavailable' : ''} product={product} /> : null}
        </div>
    ) : null
}

ServiceResult.propTypes = propTypes

export default ServiceResult
